<template>
  <div class="box_1">
    <div class="header">
      <div class="text color_fff" @click="goToMenuPage()">牲畜基本信息</div> 
    </div>

    <div class="content_box">
      <!-- 1 -->
      <div class="content_item_box">
        <div class="left_box">
          <div class="title"><span>基本信息</span></div>
          <div class="info_box color_fff">
            <div class="info_item w_full mb_10 ml_30 mt_10">
              <div>{{ oxData.基本信息[0].name }}: {{ oxData.基本信息[0].data }}</div> 
              <div>{{ oxData.基本信息[1].name }}: {{ oxData.基本信息[1].data || '无' }}</div>
            </div>
            <div class="info_item w_full mb_10 ml_30 mt_10">
              <div>{{ oxData.基本信息[2].name }}: {{ oxData.基本信息[2].data }}</div>
              <div>{{ oxData.基本信息[3].name }}: {{ oxData.基本信息[3].data }}</div>
            </div>
            <div class="info_item w_full mb_10 ml_30 mt_10">
              <div>{{ oxData.基本信息[4].name }}: {{ oxData.基本信息[4].data }}</div>
              <div>{{ oxData.基本信息[5].name }}: {{ oxData.基本信息[5].data }}</div>
            </div>
            <div class="info_item w_full mb_10 ml_30 mt_10">
              <div>{{ oxData.基本信息[6].name }}: {{ oxData.基本信息[6].data }}</div>
              <div>{{ oxData.基本信息[7].name }}: {{ oxData.基本信息[7].data }}</div>
            </div>
            <div class="info_item w_full mb_10 ml_30 mt_10">
              <div>{{ oxData.基本信息[8].name }}: {{ oxData.基本信息[8].data }}</div>
              <div>{{ oxData.基本信息[9].name }}: {{ oxData.基本信息[9].data }}</div>
            </div>
            <div class="mb_10 ml_30 mt_10">{{ oxData.基本信息[10].name }}: {{ oxData.基本信息[10].data }}</div>
          </div>
        </div>

        <div class="left_box">
          <div class="title"><span>饲料配方</span></div>
          <div class="info_box color_fff">
            <div class="w_full flex_row_around mt_10"> 
              <div class="w_25_P color_green text_left">浓缩</div>
              <div class="w_30_p text_center">当天费用： ￥1.40</div>
              <div class="w_30_p text_center">周期费用： ￥58.40</div>
            </div>

            <div class="w_full flex_row_around mt_10"> 
              <div class="w_25_P color_green text_left">豆饼</div>
              <div class="w_30_p text_center">当天费用： ￥1.90</div>
              <div class="w_30_p text_center">周期费用： ￥42.40</div>
            </div>
          </div>
        </div>

        <div class="left_box">
          <div class="title"><span>育种信息</span></div>
          <el-carousel class="swiper color_fff" direction="vertical" :autoplay="true" v-show="oxData.基本信息[8].data == '母牛'">
            <el-carousel-item class="yu_zhong_item">
              <div class="img_box w_30_p">
                <img class="full" src="../../../../assets/images/ba-lin-you-qi/xiao-niu.png" alt="">
              </div>
              <div class="w_70_p ph_10">
                <div class="mb_10">
                  育种编号：1000111 
                  <span class="ml_30 cursor" @click="goToYuZhongYuanPage()">育种员：那申</span>
                </div> 
                <div class="mb_10">育种日期：2021.1.13 <span class="ml_30"></span></div> 
                <div class="mb_10">出生日期：2021.9.15</div> 
                <div class="mb_10">牛犊性别：公</div> 
                <div class="mb_10">牛犊体重：40kg</div> 
                <div class="mb_10">健康状况：<span class="color_green">良好</span> </div> 
              </div>
            </el-carousel-item>
            <el-carousel-item class="yu_zhong_item">
              <div class="img_box w_30_p">
                <img class="full" src="../../../../assets/images/ba-lin-you-qi/xiao-niu2.png" alt="">
              </div>
              <div class="w_70_p ph_10">
                <div class="mb_10">
                  育种编号：1000221 
                  <span class="ml_30 cursor" @click="goToYuZhongYuanPage()">育种员：乌力吉</span>
                </div> 
                <div class="mb_10">育种日期：2021.12.7 <span class="ml_30"></span></div> 
                <div class="mb_10">出生日期：2022.8.10</div> 
                <div class="mb_10">牛犊性别：母</div> 
                <div class="mb_10">牛犊体重：45kg</div> 
                <div class="mb_10">健康状况：<span class="color_green">良好</span> </div> 
              </div>
            </el-carousel-item>
            <el-carousel-item class="yu_zhong_item">
              <div class="img_box w_30_p">
                <img class="full" src="../../../../assets/images/ba-lin-you-qi/xiao-niu-def.svg" alt="">
              </div>
              <div class="w_70_p ph_10">
                <div class="mb_10">
                  育种编号：1000331 
                  <span class="ml_30 cursor" @click="goToYuZhongYuanPage()">育种员：巴图</span>
                </div> 
                <div class="mb_10">育种日期：2023.4.7 <span class="ml_30"></span></div> 
                <div class="mb_10">预产日期：2024.1.7</div> 
                <div class="mb_10">牛犊性别：待定</div> 
                <div class="mb_10">牛犊体重：待定</div> 
                <div class="mb_10">健康状况：<span class="color_orange">待定</span> </div> 
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div> 

      <!-- 2 -->
      <div class="content_item_box">
        <div class="center_box">
          <div class="img_box full">
            <div class="img full">
              <img 
                class="border_radius_10 response_img fit_contain" 
                v-if="oxData.图片 && oxData.图片[1]"
                :src="oxData.图片[1]" 
                alt=""
              >
              <img class="border_radius_10 response_img fit_contain" v-else :src="oxData.图片[0]" alt="">
            </div>
          </div>
        </div>
      </div> 

      <!-- 3 -->
      <div class="content_item_box">
        <!-- 种植种类 -->
        <div class="right_box">
          <div class="title"><span>成长记录</span></div>

          <div class="grow_box" ref="tabName">
            <div 
              class="grow_name" 
              :class="{'main_color': growIndex == index}"
              v-for="(item, index) in oxData.成长记录" :key="index" 
              @click="changeGrowIndex(index)"
              @mousewheel="wheelScroll"
            >
              {{ item.阶段 }}
            </div>
          </div>

          <div class="info_box color_fff">
            <div class="info_item w_full mb_10 ml_30 mt_10">
              <div>身高: {{ oxData.成长记录[growIndex].身高 }}</div>
              <div>体重: {{ oxData.成长记录[growIndex].体重 }}</div>
            </div>
            <div class="info_item w_full mb_10 ml_30 mt_10">
              <div>十字部高: {{ oxData.成长记录[growIndex].十字部宽 }}</div> 
              <div>体斜长: {{ oxData.成长记录[growIndex].体斜长 }}</div> 
              
            </div>
            <div class="info_item w_full mb_10 ml_30 mt_10">
              <div>胸围: {{ oxData.成长记录[growIndex].胸围 }}</div>
              <div>腹围: {{ oxData.成长记录[growIndex].腹围 }}</div> 
            </div>
            <div class="mb_10 ml_30 mt_10">测量日期 {{ oxData.成长记录[growIndex].测量日期 }}</div>
          </div>
        </div>

        <div class="right_box">
          <div class="title"><span>防疫信息</span></div>
          <div class="info_box color_fff">
            <p class="mb_10 ml_30 mt_10">2022.4.5 -- 口啼疫 -- 已接种 </p>
            <p class="mb_10 ml_30 mt_10">2022.9.12 -- 高致病性禽流感 -- 已接种</p>
            <p class="mb_10 ml_30 mt_10">2022.11.21 -- 布病 -- 已接种</p>
          </div>
        </div>

        <!-- 养植类 -->
        <div class="right_box">
          <div class="title"><span>治疗信息</span></div>
          <div class="info_box color_fff">
            <p class="mb_10 ml_30 mt_10">2021.10.2 -- 牛结核病 -- 注射药物名称 </p>
            <p class="mb_10 ml_30 mt_10">2022.9.12 -- 疯牛病 -- 注射药物名称</p>
            <p class="mb_10 ml_30 mt_10">2022.11.21 -- 口啼疫 -- 注射药物名称</p>
          </div>
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
  import { getOxDetail } from '../../../../config/api';

  export default {
    data() {
      return {
        oxId: '',
        oxData: {},
        growIndex: 0,
      }
    },
    mounted() {
      this.oxId = this.$route.params.val
      this.getOxDetail(this.oxId)

    },
    methods: {
      // 获取 牛 详情
      async getOxDetail(id) {
        let params = { id: id }
        let res = await getOxDetail(params)
        // this.oxData = res.data

        if (res.data.基本信息[0].data == 6547) {
          res.data.图片[1] = ''
          res.data.图片[0] = require('@/assets/images/WenDuSu-OxImg/img1.jpg')
        } else if (res.data.基本信息[0].data == 3654) {
          res.data.图片[1] = ''
          res.data.图片[0] = require('@/assets/images/WenDuSu-OxImg/img2.jpg')
        } else if (res.data.基本信息[0].data == 2365) {
          res.data.图片[1] = ''
          res.data.图片[0] = require('@/assets/images/WenDuSu-OxImg/img3.jpg')
        } else if (res.data.基本信息[0].data == 1236) {
          res.data.图片[1] = ''
          res.data.图片[0] = require('@/assets/images/WenDuSu-OxImg/img5.jpg')
        } else if (res.data.基本信息[0].data == '000001') {
          res.data.图片[1] = ''
          res.data.图片[0] = require('@/assets/images/WenDuSu-OxImg/img4.jpg')
        } else if (res.data.基本信息[0].data == '002') {
          res.data.图片[1] = ''
          res.data.图片[0] = require('@/assets/images/WenDuSu-OxImg/img6.jpg')
        }

        this.oxData = res.data
      }, 

      // 跳转到 menu 页面
      goToMenuPage() {
        this.$router.push({
          path: "/bigscreen/menuPage"
        })
      },

      // 跳转到育种员页面
      goToYuZhongYuanPage() {
        this.$router.push({
          path: "/bigscreen/yuZhongJiLu"
        })
      },

      // 监听 点击 成长记录 tabName 切换成长记录
      changeGrowIndex(index) {
        this.growIndex = index
      },

      // 鼠标滚轮控制 成长记录TabName 横向滚动
      wheelScroll(e) {
        let box = this.$refs.tabName
        e.preventDefault()
        box.scrollLeft += e.deltaY
      }
    }
  }
</script>

<style scoped lang="less">
  .activeColor { color: #2366fc !important; }
  .left_box { 
    height: 33% !important;
    margin-top: 0 !important;
    margin-bottom: 10px !important;
  }
  .right_box { 
    height: 33% !important;
    margin-top: 0 !important;
    margin-bottom: 10px !important;
  }

  .info_item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    // color: rgb(13, 246, 94);

    div:nth-child(1) { width: 45%; }
    div:nth-child(2) { width: 55%; }
  }

  .swiper { 
    width: 100%;
    height: 91% !important;
  }

  .yu_zhong_item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 8px;
    box-sizing: border-box;

    .img_box {
      img { border-radius: 10px; }
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1399px) {
  }
  @media screen and (min-width: 1400px) and (max-width: 1699px) {
  }
</style>

<style>
.el-carousel__container { height: 100% !important; }
</style>
import request from '@/util/request'

// 获取 巴林右旗 乡镇统计
export function getXiangZhenTongJi() {
    return request({
        // url: '/nmbigdata/cattlecf/tj/xztj',
        url: '/nmbigdata/cattlecf/tj2/xz',
        method: 'get',
    })
}

// 获取 巴林右旗 乡镇下的嘎查村统计
export function getGaChaCunTongJi(data) {
    return request({
        // url: `/nmbigdata/cattlecf/tj/gctj?xz=${data.xz}`,
        url: `/nmbigdata/cattlecf/tj2/gc?village=${data.xz}`,
        method: 'get',
    })
}

// 获取 巴林右旗 某一个嘎查村统计
export function getOneGaChaCunTongJi(data) {
    return request({
        url: `/nmbigdata/cattlecf/tj2/gc?village=${data.village}&hamlet=${data.hamlet}`,
        method: 'get',
    })
}

// 获取 巴林右旗 牧户list
export function getMuHuList(data) {
    return request({
        url: `/nmbigdata/cattlecf/yzh?village=${data.village}`,
        method: 'get',
    })
}

// 获取 巴林右旗 牧户详情
export function getMuHuDetail(data) {
    return request({
        url: `/nmbigdata/cattlecf/yzh/${data.id}`,
        method: 'get',
    })
}

// 获取 牛 详情
export function getOxDetail(data) {
    return request({
        url: `/nmbigdata/cattlecf/cattle/api/${data.id}`,
        method: 'get',
    })
}


